<template>
  <div>
    <Main>
      <ErrorWrapper>
        <img :src="require(`@/static/img/pages/404.svg`)" alt="404" />
        <sdHeading class="error-text" as="h3">
          403
        </sdHeading>
          <h3>Forbidden</h3>
          <p>You don't have permission to access this page</p>        <router-link to="/">
          <sdButton size="default" type="primary" to="/">
            Return Home
          </sdButton>
        </router-link>
      </ErrorWrapper>
    </Main>
  </div>
</template>

<script>
import {Main} from '@/components/shared/styledComponents/Main';
import {ErrorWrapper} from './style';
export default {
  name: '404',
  components: {
    Main,
    ErrorWrapper
  },
  data() {
    return {};
  }
};
</script>
